@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
  background-color: #000; /* Black background */
  color: #f8f8f8; /* Light-colored text */
}

.hero-section {
  text-align: center;
  padding: 50px 20px;
}

.text-container {
  margin-bottom: 20px; /* Space between text and image */
}

.hero-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px; /* Space between image and buttons */
}

.buttons-container {
  display: flex;
  flex-direction: column; /* Stack buttons vertically on mobile */
  align-items: center;
}

.btn {
  background-color: #61dafb; /* Light blue background for buttons */
  color: #000; /* Black text for buttons */
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  width: 80%; /* Ensure buttons are not too wide on mobile */
}

/* Media query for desktop screens */
@media (min-width: 768px) {
  .buttons-container {
    flex-direction: row; /* Side-by-side buttons on desktop */
    justify-content: center;
  }
  
  .btn {
    width: auto; /* Adjust width based on content */
  }
}

/* Additional styling for links */
a {
  color: #61dafb;
}

a:hover {
  color: #ffffff;
}
